import {getAPI} from "@/services/axios-api";

const state = () => ({
    categories: {
        'huître-en-ligne': {
            id: 1,
            name: 'oyster_categ',
            description: 'oyster_categ_description',
            image: "banner_categ/categorie-huitres-famille-boutrais.jpg",
            srcset: "banner_categ/categorie-huitres-famille-boutrais-small.jpg 320w,banner_categ/categorie-huitres-famille-boutrais.jpg 640w,banner_categ/categorie-huitres-famille-boutrais-big.jpg 1280w",
            meta_image: "home/huitre-en-ligne-boutrais_medium.png",
            meta_image_srcset : "home/huitre-en-ligne-boutrais_small.png 320w,home/huitre-en-ligne-boutrais_medium.png 640w,home/huitre-en-ligne-boutrais.png 1280w",
        },
        'epicerie-en-ligne': {
            id: 4,
            name: 'grocery_categ',
            description: 'grocery_categ_description',
            image: "banner_categ/categorie-epicerie-famille-boutrais.jpg",
            srcset: "banner_categ/categorie-epicerie-famille-boutrais-small.jpg 320w,banner_categ/categorie-epicerie-famille-boutrais.jpg 640w,banner_categ/categorie-epicerie-famille-boutrais-big.jpg 1280w",
            meta_image: "home/epicerie-en-ligne-boutrais-medium.png",
            meta_image_srcset : "home/epicerie-en-ligne-boutrais-small.png 320w,home/epicerie-en-ligne-boutrais-medium.png 640w,home/epicerie-en-ligne-boutrais.png 1280w",
        },
        'vin-en-ligne': {
            id: 5,
            name: 'cellar_categ',
            description: 'cellar_categ_description',
            image: "banner_categ/categorie-cave-famille-boutrais.jpg",
            srcset: "banner_categ/categorie-cave-famille-boutrais-small.jpg 320w,banner_categ/categorie-cave-famille-boutrais.jpg 640w,banner_categ/categorie-cave-famille-boutrais-big.jpg 1280w",
            meta_image: "home/cave-vin-blanc-en-ligne-boutrais-medium.png",
            meta_image_srcset : "home/cave-vin-blanc-en-ligne-boutrais-small.png 320w,home/cave-vin-blanc-en-ligne-boutrais-medium.png 640w,home/cave-vin-blanc-en-ligne-boutrais.png 1280w",
        },
        'accessoires': {
            id: 7,
            name: 'accessory_categ',
            description: 'accessory_categ_description',
            image: "banner_categ/categorie-accesoires-famille-boutrais.jpg",
            srcset: "banner_categ/categorie-accesoires-famille-boutrais-small.jpg 320w,banner_categ/categorie-accesoires-famille-boutrais.jpg 640w,banner_categ/categorie-accesoires-famille-boutrais-big.jpg 1280w",
            meta_image: "home/accesoires-huitres-en-ligne-boutrais-medium.png",
            meta_image_srcset : "home/accesoires-huitres-en-ligne-boutrais-small.png 320w,home/accesoires-huitres-en-ligne-boutrais-medium.png 640w,home/accesoires-huitres-en-ligne-boutrais.png 1280w",
        },
        9: {
            id: 9,
            name: "how_it_work",
            description: "how_it_work_desc",
            image: "banner_categ/categorie-commentcamarche-famille-boutrais.jpg",
            srcset: "banner_categ/categorie-commentcamarche-famille-boutrais-small.jpg 320w,banner_categ/categorie-commentcamarche-famille-boutrais.jpg 640w,banner_categ/categorie-commentcamarche-famille-boutrais-big.jpg 1280w",
            meta_image: "home/lfb_home_pres.webp",

        },
        10: {
            id: 10,
            name: "who_are_we",
            description: "who_are_we_desc",
            image: "banner_categ/categorie-quisommesnous-famille-boutrais.jpg",
            srcset: "banner_categ/categorie-quisommesnous-famille-boutrais-small.jpg 320w,banner_categ/categorie-quisommesnous-famille-boutrais.jpg 640w,banner_categ/categorie-quisommesnous-famille-boutrais-big.jpg 1280w",
        },
        11: {
            id: 11,
            name: "saint_michel",
            description: "saint_michel_desc",
            image: "banner_categ/categorie-quisommesnous-famille-boutrais.jpg",
            srcset: "banner_categ/categorie-quisommesnous-famille-boutrais-small.jpg 320w,banner_categ/categorie-quisommesnous-famille-boutrais.jpg 640w,banner_categ/categorie-quisommesnous-famille-boutrais-big.jpg 1280w",
        },
        12: {
            id: 12,
            name: "",
            description: "",
            image: "banner_categ/categorie-quisommesnous-famille-boutrais.jpg",
            srcset: "banner_categ/categorie-quisommesnous-famille-boutrais-small.jpg 320w,banner_categ/categorie-quisommesnous-famille-boutrais.jpg 640w,banner_categ/categorie-quisommesnous-famille-boutrais-big.jpg 1280w",
        },
        13: {
            id: 13,
            name: "bannow",
            description: "bannow_desc",
            image: "banner_categ/categorie-quisommesnous-famille-boutrais.jpg",
            srcset: "banner_categ/categorie-quisommesnous-famille-boutrais-small.jpg 320w,banner_categ/categorie-quisommesnous-famille-boutrais.jpg 640w,banner_categ/categorie-quisommesnous-famille-boutrais-big.jpg 1280w",
        },
        14: {
            id: 14,
            name: "trawenagh",
            description: "trawenagh_desc",
            image: "banner_categ/categorie-quisommesnous-famille-boutrais.jpg",
            srcset: "banner_categ/categorie-quisommesnous-famille-boutrais-small.jpg 320w,banner_categ/categorie-quisommesnous-famille-boutrais.jpg 640w,banner_categ/categorie-quisommesnous-famille-boutrais-big.jpg 1280w",
        },
        15: {
            id: 15,
            name: "dundrum",
            description: "dundrum_desc",
            image: "banner_categ/categorie-quisommesnous-famille-boutrais.jpg",
            srcset: "banner_categ/categorie-quisommesnous-famille-boutrais-small.jpg 320w,banner_categ/categorie-quisommesnous-famille-boutrais.jpg 640w,banner_categ/categorie-quisommesnous-famille-boutrais-big.jpg 1280w",
        },
        17: {
            id: 17,
            name: "point_of_sales",
            description: "point_of_sales_desc",
            image: "banner_categ/categorie-nos-points-de-vente-famille-boutrais-medium.jpg",
            srcset: "banner_categ/categorie-nos-points-de-vente-famille-boutrais-small.jpg 320w,banner_categ/categorie-nos-points-de-vente-famille-boutrais-medium.jpg 640w,banner_categ/categorie-nos-points-de-vente-famille-boutrais-big.jpg 1280w",
        },
        18: {
            id: 18,
            name: "services",
            description: "services_desc",
            image: "banner_categ/categorie-nos-prestations-famille-boutrais-medium.jpg",
            srcset: "banner_categ/categorie-nos-prestations-famille-boutrais-small.jpg 320w,banner_categ/categorie-nos-prestations-famille-boutrais-medium.jpg 640w,banner_categ/categorie-nos-prestations-famille-boutrais-big.jpg 1280w",
        },
        19: {
            id: 19,
            name: "commitment_fb",
            description: "commitment_fb_desc",
            image: "banner_categ/categorie-nos-engagements-medium.jpg",
            srcset: "banner_categ/categorie-nos-engagements-small.jpg 320w,banner_categ/categorie-nos-engagements-medium.jpg 640w,banner_categ/categorie-nos-engagements-big.jpg 1280w",
        },
        20: {
            id: 20,
            name: "contact_us",
            description: "contact_us_desc",
            image: "banner_categ/categorie-nous-contacter-famille-boutrais-medium.jpg",
            srcset: "banner_categ/categorie-nous-contacter-famille-boutrais-small.jpg 320w,banner_categ/categorie-nous-contacter-famille-boutrais-medium.jpg 640w,banner_categ/categorie-nous-contacter-famille-boutrais-big.jpg 1280w",
        },
    },
    category: null,
});

const getters = {
    getCategoryId: function (state) {
        return state.category
    },
    getCategory: function (state) {
        return state.categories[state.category]
    },
    getCategories: function (state) {
        return state.categories
    }
};

const actions = {};

const mutations = {
    setCategory: function (state, data) {
        localStorage.setItem('c', data)
        state.category = data
    }
};

export default {
    namespaced: true, state, getters, actions, mutations,
};