import {getAPI} from "@/services/axios-api";

const state = () => ({
    cacheKey: null,
    accessToken: null,
    refreshToken: null,
    isAuthenticated: false,
    userId: null,
    located: false,
    location: null,
});

const getters = {
    getCacheKey: function (state) {
        return state.cacheKey
    },
    getAccessToken: function (state) {
        return state.accessToken;
    },
    getLocated: function (state) {
        return state.located
    },
    getLocation: function (state) {
        return state.location
    },
    getIsAuthenticated: function (state) {
        return state.isAuthenticated
    },
    getUserId: function (state) {
        return state.userId
    },
};

const actions = {
    setLocation: async function (context, data) {
        getAPI.defaults.headers.common['X-Long'] = data.longitude;
        getAPI.defaults.headers.common['X-Lat'] = data.latitude;
        if (!data.hasOwnProperty('server')) {
            await context.dispatch('setServerCache', {"location": JSON.stringify(data)})
        } else {
            delete data["server"]
        }
        context.commit('setLocation', data)
        await context.dispatch('pricelist/loadPricelistTransport', null, {root: true})
        context.commit('setLocated', true)
    },
    getServerCacheKey: async function (context) {
        await getAPI.get('/account/cache/key/').then(r => {
            const key = r.data.cache_key
            localStorage.setItem("cache_key", key)
            context.commit('setCacheKey', key)
        })
    },
    getServerCacheInfo: async function (context) {
        await getAPI.get('account/cache/', {
            params: {
                key: context.getters.getCacheKey
            }
        })
            .then(r => {
                const data = r.data
                const access = sessionStorage.getItem("access");
                const refresh = sessionStorage.getItem("refresh");
                let user_id = data.user_id ? data.user_id : null;
                if (!user_id) {
                    getAPI.get('account/profile/user-id/').then(r=> {
                        user_id = r.data
                    }).catch(r => user_id = null)
                }
                if (data.location) {
                    const location = {
                        longitude: 2.333333,
                        latitude: 48.866667,
                        zip: '75000',
                        country: 'France',
                        city: 'Paris',
                    }
                    location['server'] = true
                    context.dispatch('setLocation', location);
                }
                if (data.cart) context.dispatch('cart/initializeCart', data.cart, {root: true});
                if (user_id) context.commit('initializeStore', {access: access, refresh: refresh, userId: user_id})
            })
    },
    setServerCache: async function (context, data) {
        await getAPI.post('account/cache/', data, {
            params: {
                key: context.getters.getCacheKey
            }
        }).catch(r => {
            console.log(r.data)
        })
    },
    initializeStore: async function (context) {
        const cache_key = localStorage.getItem("cache_key")
        if (cache_key) {
            context.commit('setCacheKey', cache_key)
            await context.dispatch('getServerCacheInfo')
        } else {
            await context.dispatch('getServerCacheKey')
        }
    },
    registration: async function (context, credentials) {
        return new Promise(async (resolve, reject) => {
            await getAPI
                .post('account/registration/', credentials)
                .then(response => {
                    context.dispatch('setServerCache', {"user_id": response.data.data.user_data.id});
                    context.commit('setUserId', response.data.data.user_data.id);
                    context.dispatch('getServerCacheInfo');
                    let error = {'message': null, 'detail': null};
                    context.dispatch('error/setError', error, {root: true})
                    resolve();
                })
                .catch(err => {
                    let error = {'message': err.message, 'details': err.response.data.error};
                    context.dispatch('error/setError', error, {root: true})
                    reject(err)
                })
        })
    },
    login: async function (context, credentials) {
        return new Promise(async (resolve, reject) => {
            await getAPI
                .post('/account/login/', credentials)
                .then(response => {
                    context.commit('setToken', {
                        access: response.data.tokens.access,
                        refresh: response.data.tokens.refresh,
                    })
                    context.dispatch('setServerCache', {"user_id": response.data.userId});
                    context.commit('setUserId', response.data.userId);
                    let userLocation = {
                        'longitude': response.data.longitude,
                        'latitude': response.data.latitude,
                        'zip': response.data.zip,
                        'city': response.data.city,
                        'country': response.data.country,
                    }
                    if (!context.state.location) {
                        context.dispatch('setLocation', userLocation);
                    }
                    let error = {'message': null, 'detail': null};
                    context.dispatch('error/setError', error, {root: true})
                    resolve();
                })
                .catch(err => {
                    let error = {'message': err.message, 'detail': err.response.data.error};
                    context.dispatch('error/setError', error, {root: true})
                    reject(err.response.data.error)
                })

        })
    },
    logout: function (context) {
        context.commit('removeToken');
        sessionStorage.setItem('isAuthenticated', false);
    },
    refreshToken: function ({commit}, access) {
        commit('refreshToken', access)
    },
    removeToken: function ({commit}) {
        commit('removeToken')
    }
};

const mutations = {
    setUserId: function (state, userId) {
        state.userId = userId
    },
    setCacheKey(state, key) {
        state.cacheKey = key
    },
    setLocated: function (state, located) {
        state.located = located;
    },
    setLocation: function (state, location) {
        state.location = location;
    },
    initializeStore: function (state, data) {
        if (data.access && data.refresh) {
            state.accessToken = data.access;
            state.refreshToken = data.refresh;
            state.isAuthenticated = true;
            state.userId = data.userId;
        } else {
            state.accessToken = null;
            state.refreshToken = null;
            state.isAuthenticated = false;
            state.userId = null;
        }
    },
    setToken: function (state, tokens) {
        state.accessToken = tokens.access;
        state.refreshToken = tokens.refresh;
        state.isAuthenticated = true;
        sessionStorage.setItem('access', tokens.access);
        sessionStorage.setItem('refresh', tokens.refresh);
        sessionStorage.setItem('isAuthenticated', state.isAuthenticated);
    },
    refreshToken: function (state, access) {
        state.isAuthenticated = true;
        state.accessToken = access;
    },
    removeToken: function (state) {
        state.accessToken = null
        state.refreshToken = null
        state.isAuthenticated = false
        sessionStorage.removeItem('access')
        sessionStorage.removeItem('refresh')
    },

};

export default {
    namespaced: true, state, getters, actions, mutations,
};